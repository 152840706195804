.first90Days__list {
  list-style: inside;
  padding-top: 30px;
  padding-bottom: 50px;
}

.first90Days__list li {
  margin-bottom: 10px;
}

.first90Days__secondlist {
  padding-left: 4%;
  list-style: decimal;
  /* padding: 0; */
  margin-top: -25px;
}

/* 
.first90Days {
  padding: 3% 0 1%;
  background-color: var(--second-blue);
}


.first90days__item {
  margin-bottom: 50px;
  color: var(--white, #FFF);
  font-size: 24px;
  font-weight: 300;
  line-height: 148.023%;
}

.first90days__item span {
  font-weight: 700;
} 

.title__container {
  margin-bottom: 100px;
}

.first90days__caption {
  margin: 20px 0 50px 50px;
}

.first90days__list {
  list-style-type: decimal;
}

.first90days__sublist {
  list-style-type: circle;
  margin-left: 70px;
  margin-bottom: 25px;
}
.first90days__sublist li{
  margin-bottom: 25px;
}


@media (max-width:1480px) {
  .title__container {
      margin-bottom: 10%;
    }
  .first90days__item, .first90days__item *{
    font-size: 22px;
  }
}


@media (max-width:1280px) {
  .first90days__item,
    .first90days__item * {
      font-size: 20px;
    }
}

@media (max-width:1080px) {
  .first90days__item,
    .first90days__item * {
      font-size: 18px;
    }

}

@media (max-width:670px) {
  .first90days__item,
    .first90days__item * {
      font-size: 16px;
    }
    .first90days__sublist {
      margin: 0 0 20px 20px;
    }
    .first90days__list {
      margin: 0 0 0px 10px;
    }
    .first90days__caption {
      margin: 10px 0 20px 20px;
    }
.first90days__item {
  margin-bottom: 20px;

}
/* 
.title__container {
  margin-bottom: 20px;
} */

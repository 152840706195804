body {
  font-family: 'Inter', sans-serif;
  color: var (--grey-text, #242424);
  overflow: auto;
  font-size: 24px;
  font-weight: 400;
  line-height: 150%;
}
body * {
    transition: all .3s;
}
html,
body {
  position: relative;
  height: 100%;
}
:root {
  --white: #FFFFFF;
  --main-blue:#096BB4;
  --blue:#033F85;
  --grey:#F1F5F6;
  --dark-grey:#2F3338;
  --grey-text:#242424;
  --yellow:#FFCB00;
  --white-blue: #F1F5F6;
  --second-blue: linear-gradient(180deg, rgba(9, 107, 180, 1) 0%, rgba(3, 64, 134, 1) 100%);
  --hover-blue: linear-gradient(180deg, rgba(3, 64, 134, 1) 0%, rgba(9, 107, 180, 1) 100%);
}

@media (max-width:770px)  {
  body.modalOpen {
  overflow: hidden;
 }
}



h2 {
  color: var(--grey-text );
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
@media (max-width: 1080px) {
  h2 {
    font-size: 32px;
  }
}
@media (max-width: 870px) {
  h2 {
    font-size: 28px;
  }
}
@media (max-width: 540px) {
  h2 {
    font-size: 24px;
  }
}
@media (max-width: 370px) {
  h2 {
    font-size: 18px;
  }
}

.container {
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
}

@media screen and (max-width: 1480px) {
  .container {
      max-width: 1240px;
  } 
}
@media (max-width: 1280px) {
  .container {
      max-width: 1024px;
  } 
}
@media (max-width: 1080px) {
  .container {
      max-width: 940px;
  } 
}
@media (max-width: 980px) {
  .container {
      max-width: 780px;
  } 
}


.arrow { 
  position:fixed;
  bottom: 20px;
  right: 20px;
}
#app {
  height: 100%
}




.swiper {
  width: 100%;
  height: auto;
  padding: 50px;
  margin-bottom: 60px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
  width: 100%;
}
.swiper-pagination {
  margin-bottom: -20px;
}


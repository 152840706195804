
.title__container {
  max-width: 800px;
  margin-bottom: 40px;
}

.questions__container {
  margin-bottom: 40px;
}

@media (max-width:1440px) {
  .questions__container {
      margin-bottom: 4%;
    }
}
.facts {
  background: var(--second-blue);
  margin-bottom: 120px;
  padding: 80px;
}

.facts__numbers {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-basis:100%;

}
@media (max-width: 1400px) {
.facts {
    margin-bottom: 10%;
  }
}
@media (max-width: 1240px) {

}
@media (max-width: 1080px) {

  .facts {
    padding: 50px;
  }
  
}
@media (max-width: 870px) {

}
@media (max-width: 760px) {
  .facts {
    padding: 30px;
  }
}
@media (max-width: 650px) {

}
@media (max-width: 540px) {
  .facts__numbers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows:  1fr 1fr;
  }
  .facts {
    padding: 15px;
  }
}
@media (max-width: 470px) {
  .facts__numbers {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows:  130px;
  }
}

.facts__fact {
  display: grid;
  grid-template-rows: 4fr 3fr;
  justify-items:center;
  align-items:center;
  flex-basis:50%;
  color:  #fff;
}

.facts__number {
  font-size: 120px;
  margin-bottom: 30px;
  margin-top: 30px;
  align-self: end;

  
}

.facts__caption {
  margin-top: 20px;
  font-size: 20px;
  line-height: 130%; 
  text-align: center; 
  justify-self:center;
  align-self:start;
}

@media (max-width: 1240px) {
  .facts__number {
    font-size: 80px;
    margin-bottom: 20px;
  }
  .facts__caption {
    font-size: 18px;
    margin-top: 10px;
  }
}

@media (max-width: 870px) {
  .facts__number {
      font-size: 60px;
      margin-bottom: 20px;
    }
  .facts__caption {
      font-size: 16px;
      margin-top: 0px;
    }
}

@media (max-width: 780px) {
  .facts__number {
      font-size: 48px;
      margin-bottom: 10px;
  }
  .facts__caption {
    font-size: 14px;
      margin-top: 0px;
  }
}
@media (max-width: 650px) {
  .facts__number {
    font-size: 42px;
  }
  .facts__caption {
    font-size: 11px;
  }
}
@media (max-width: 540px) {
  .facts__caption {
    font-size: 11px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}


.top {
   border-top: 1px solid #1D66A7;
}
.left {
   border-left: 1px solid #1D66A7;
}
.bottom {
   border-bottom: 1px solid #1D66A7;
}
.right {
   border-right: 1px solid #1D66A7;
}
@media (max-width: 540px) {
  .top, .left, .bottom, .right  {
  border: 0px solid #1D66A7;
  }
}

.facts__items {
  display: grid;
  width: 100%;
  grid-template-columns:  1fr 1fr;
  grid-template-rows: repeat(2, 220px);
  padding-bottom: 20px;
}


.facts__item {
  color:var(--white);
  display: flex;

}


.facts__item_numbers {
  font-size: 50px;
  font-weight: 700;
justify-content:end;
}

.facts__item_caption {

  margin-left: 15px;
  font-size: 24px;
}

.facts__item_numbers span {
color:var(--yellow);
}

.facts__item_bigCaption {
    font-size: 28px;
}

.facts__item_img {
  margin-right: 10px;
  min-width: 30px;
  height: auto;
}

@media (max-width: 1480px) {
.facts__items {
    grid-template-columns: 600px 1fr;
    grid-template-rows: repeat(2, 180px); 
  }
.facts__item_bigCaption {
  font-size: 24px;
  line-height: 135%;
  }
}


@media (max-width: 1240px) {
  .facts__items {
    grid-template-columns: 500px 1fr;
    grid-template-rows: repeat(2, 150px);
  }
  .facts__item_numbers {
    font-size: 44px;
  }
  .facts__item_caption  {
    font-size: 18px;
  }
  .facts__item_bigCaption {
    font-size: 22px;
  }
}
@media (max-width: 1080px) {
.facts__items {
    grid-template-columns: 400px 1fr;
    grid-template-rows: repeat(2, 140px);
  }
.facts__item_numbers {
    font-size: 40px;
  }
.facts__item_caption {
    font-size: 16px;
  }
.facts__item_bigCaption {
    font-size: 18px;
  }
.facts__item_img {
  width: 25px;
  }
}

@media (max-width: 870px) {
.facts__items {
    grid-template-columns: 360px 1fr;
    grid-template-rows: repeat(2, 140px);
  }
  .facts__item_numbers {
    font-size: 36px;
  }
  .facts__item_caption {
    font-size: 14px;
  }
  .facts__item_bigCaption {
    font-size: 14px;
  }
  .facts__item_img {
    width: 20px;
  }
}

@media (max-width: 780px) {
.facts__items {
    grid-template-columns:  1fr;
    grid-template-rows: repeat(4, 80px);
    align-items:start;
    justify-items: start;
  }
.facts__number {
    font-size: 42px;
  }

.facts__caption {
    font-size: 14px;
  }
}

@media (max-width: 650px) {
  .facts__number {
    font-size: 38px;
  }

  .facts__caption {
    font-size: 11px;
  }
}

@media (max-width: 540px) {
  .facts__caption {
    font-size: 11px;
  }
}

@media (max-width: 420px) {
  .facts__item_numbers {
      font-size: 28px;
    }
    .facts__item_caption {
      margin-left: 5px;
    }
}
@media (max-width: 370px) {
  .facts__item_numbers {
    font-size: 22px;
  }
  .facts__item_caption {
    font-size: 10px;
  }
  .facts__item_bigCaption {
    font-size: 10px;
  }
}

.card {
  height: 330px;
  width: 400px;
  background: var(--second-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  color:var(--white);
  position:relative;
  transition: all .3s;
}

@media (max-width:1280px) {
  .card {
    width: 350px;
  }
}
@media (max-width:1140px) {
  .card {
    width: 300px;
  }
}
@media (max-width:980px) {
  .card {
    height: 210px;
    width: 230px;
  }
}

@media (max-width:768px) {
  .card {
    height: 160px;
    width: 400px;
  }
}
@media (max-width:480px) {
  .card {
    height: auto;
    min-height: 170px;
    width: 90%;
  }
}

.card:hover {
  margin-top: -20px;
}

.card__img {
  width: 116px;
  height: 116px;
  border-radius: 50%;
  border:2px solid var(--white);
  background: var(--main-blue);
  position:absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top:-58px;
}
@media (max-width:1140px) {
  .card__img {
    width: 90px;
    height: 90px;
  }
  .card__img img {
    width: 50px;
  }
}

.card__text {
  margin: 30px;
  text-align: center;
  color: var(--White, #FFF);
  font-weight: 400;
  line-height: 150%;
}
@media (max-width:1280px) {
  .card__text {
    font-size: 20px;
  }
}
@media (max-width:980px) {
  .card__text {
    font-size: 16px;
  }
}
.footer {
  background: var(--second-blue);
  padding: 50px;
  color: var(--white);
  font-weight: 300;
  font-size: 20px;
  line-height: 130%;
}

.footer__logo {
  max-height: 70px;
  height: 10%;
}
.footer__line {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 20px;
}

.footer__wrapper, .footer__address{
  max-width: 325px;
}
.footer__wrapper img {
  margin-bottom: 20px;
}

.footer__links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 45px;
}
.footer__links img {
  width: 50px;  
  height: 50px;
}


.footer__title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

@media (max-width:1480px) {}


@media (max-width:1280px) {
  .footer__line {
      gap: 15px;
    }
    .footer__links {
      flex-direction: column;
    }
    .footer__text, p, a {
      font-size: 18px;
    }
    .footer__address {
      width: 260px;
    }

}

@media (max-width:1080px) {
  .footer__line {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-template-rows: 200px 1fr;
    }
    .footer__links {
      flex-direction: row;
      justify-content: start;
    }
}

@media (max-width:670px) {
  .footer {
      padding: 30px 0px;
    }
  .footer__line {
      display: flex;
      flex-direction: column;
      gap: 30px;
      align-items: center;
    }
    .footer__line * {
      text-align: center;
      font-size: 16px;
    }
    .footer__text {
      width: 260px;
    }
    .footer__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .footer__title {
      margin-bottom: 0px;
    }

}

@media (max-width:370px) {
  /* .footer__wrapper {
    width: 300px;
  } */
}
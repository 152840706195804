.content {
  display: flex;
  align-items: stretch;
  border: solid 2px var(--white-blue);
  margin-bottom: 60px;
}


.left_side {
  flex: 0 0 50%;
  padding: 60px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.ssend{
  text-align: center;
  margin-top: 35px;
  background-color: #ffcb00;
  padding: 10px;
}
@media screen and (max-width:1240px) {
  .left_side {
  padding: 40px;
  }
}
@media (max-width: 980px) {
  .left_side {
    padding: 25px;
  }
}

.right_side {
  padding: 80px 50px 0;
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background:  url('../../assets/img/formpic.png') 50%/cover no-repeat;
}

.icon {
  margin-bottom: 50px;
}
.text{
  text-align: center;
}
.details { 
  margin-bottom: 15px;
  text-align: center;
}

.details i {
  font-size: 25px;
  color: #3e2093;
  margin-bottom: 10px;
}

.topic {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 7px;
}

.text_one,
.text_two {
  font-size: 14px;
  color: #afafb6;
}

.topic_text {
  font-size: 32px;
  text-align: center;
  font-weight: 600;
  color: var(--white);
  text-transform: uppercase;
  margin-bottom: 10px;
}

.right_side p {
  color: var(--white);
  margin-bottom: 20px;
}

.input_box {
  height: 50px;
  width: 100%;
  margin-bottom: 30px;
}
@media (max-width: 980px) {
  .input_box {
    margin-bottom: 25px;
  }
}

.input_box input,
.input_box textarea {
  height: 100%;
  width: 100%;
  border: none;
  background: var(--white-blue);
  padding: 0 20px;
}
@media (max-width: 980px) {
  .input_box input,
  .input_box textarea {
    font-size: 18px;
}
}


.input_box textarea {
  resize: none;
  padding: 20px;
}

.message_box {
  min-height: 110px;
}

.button {
  display: block;
}

.button {
  width: 100%;
  color: var(--white);
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
  background: var(--second-blue);
  outline: none;
  border: none;
  padding: 10px 20px;
  transition: .5s;
}

.button:hover {
    color: var(--white-blue);
    background: var(--hover-blue);
    cursor: pointer;
}
.button:disabled {
    color: var(--white );
    cursor:auto;
    background: var(--dark-grey);
}


@media (max-width: 800px) {
  .content {
    height: 100%;
    flex-direction: column-reverse;
  }

  .left-side {
    margin-top: 50px;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }

  .details {
    margin-right: 20px;
  }

  .details:last-child {
    margin-right: 0;
  }

  .left-side::before {
    display: none;
  }

  .right-side {
    width: 100%;
    margin-left: 0;
  }
}

.input_box label {
  font-size: 16px;
  color: #b11d1d;
  position: relative;
  top:-10px;
}
@media (max-width: 800px) {
.input_box label {
    font-size: 14px;
  }
}

.falied {
  outline: 2px solid red;
}

.textarea {
  margin-bottom: -10px;
}
.cards {
  margin-top: -150px;
  margin-bottom: 150px;
  display: flex;
  justify-content: center;
  gap:20px;
}

@media (max-width:1440px) {
.cards {
    margin-bottom: 10%;
  }
}
@media (max-width:980px) {
  .cards {
    gap: 10px;
  }
}
@media (max-width:780px) {
  .cards {
    margin-top: -70px;
  }
}
@media (max-width:768px) {
  .cards {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 70px;
  }
}
.cards:last-child {
  font-weight: 700;
  /* font-size: 28px; */
}
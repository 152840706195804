.firstDay__list {
  list-style:inside;
}

.firstDay__text {
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  text-transform:uppercase;
}
.firstDay__text span {
  color: var(--main-blue);
  font-size: 24px;
} 

.firstDay {
  padding-bottom: 50px;
  padding-top: 30px;
}



/* .firstDay {
  background-color: var(--second-blue);
} */

/* .title__container {
  margin-bottom: 90px;
}


.firstDay__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 70px;
  margin-bottom: 120px;
}

.list {
  color: var(--white, #FFF);
}  



.list__title {
  font-size: 32px;
  width: 100%;
  position: relative;
  line-height: 163%;
  font-weight: 400;
}



.list__title span {
  font-size: 40px;
  margin-right: 20px;
}

.list__text {
  font-weight: 300;
  margin-bottom: 25px;
  font-size: 24px;
  line-height: 150%;
  color:var(--white);
}

.list__text ul {
  margin-top: 25px;
  margin-left: 20px;

}
.list__text ul li {
  font-weight: 300;
  margin-bottom: 10px;
  list-style: inside;
}

.firstDay__title {
  position: relative;
  color: var(--white);
  text-align: center;
  font-weight: 700;
  font-size: 40px;  
  text-align: center;
  margin-bottom: 90px;
}

.accordion__container {
  margin-bottom: 80px;
}


.firstDay__row {
  display: flex;
  justify-content: space-between;
  align-items: start;
  color:var(--white);
  font-size: 36px;
}

.firstDay__item {
  flex: 0 0 45%;
}

.firstDay__row ul li {
  font-size: 24px;
  font-weight: 300;
  letter-spacing: normal;
  list-style: inside;
  margin-bottom: 10px;
}
.firstDay__row ul {
  margin-top: 25px;
  margin-left: 30px;
  line-height: 100%;
}

@media (max-width:1480px) {
  .accordion__container {
      margin-bottom: 70px;
    }

.firstDay__container {
    margin-bottom: 10%;
  }

.firstDay__title {
  font-size: 36px;
}
.firstDay__title:before {
  width: 200px;
  height: 2px;
  top: 25px;
  margin-left: -250px;
}

.firstDay__item {
  font-size: 26px;
  line-height: 150%;
}
.firstDay__item ul li {
  font-size: 22px;
  margin-bottom: 10px;
  line-height: 150%;
}

.list__title {
    font-size: 28px;
    margin-bottom: 55px;
}

.list__title span {
  font-size: 36px;
  margin-right: 20px;
}

.list__text {
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 20px;
  line-height: 130%;
}

.list__text ul {
  margin-top: 15px;
  margin-left: 20px;
}

.list__text ul li {
  margin-bottom: 10px;
}
}

@media (max-width:1480px) {
  .title__container {
      margin-bottom: 10%;
    }
  .list__title {
    font-size: 28px;
    margin-bottom: 55px;
  }

  .list__title::after {
    bottom: -25px;
    width: 120px;
  }

  .list__title span {
    font-size: 36px;
    margin-right: 20px;
  }

  .list__text {
    font-weight: 300;
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 130%;
  }

  .list__text ul {
    margin-top: 15px;
    margin-left: 20px;
  }

  .list__text ul li {
    margin-bottom: 10px;
  }
}


@media (max-width:1280px) {
  .firstDay__title {
      font-size: 30px;
      margin-bottom: 70px;
    }

.accordion__container {
  margin-bottom: 50px;
}

  .firstDay__item {
    font-size: 20px;
    line-height: 150%;
  }
  .firstDay__item ul li {
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 150%;
  }

  .list__title {
    font-size: 24px;
    margin-bottom: 35px;
  }
  .list__title span {
    font-size: 30px;
    margin-right: 20px;
  }

  .list__title::after {
    bottom: -15px;
    width: 100px;
  }

  .list__text {
    font-size: 18px;
    line-height: 150%;
  }
}

@media (max-width:1080px) {
  .firstDay__container {
    grid-template-columns: 1fr;
    grid-gap: 50px;

  }
  .accordion__container {
    margin-bottom: 40px;
  }
}

@media (max-width:870px) {
  .firstDay__title{
    font-size: 24px;
    margin-bottom: 40px;
  }
    .firstDay__title:before {
display: none;
  }
  .firstDay__title:after {
    display: none;
  } 
  .firstDay__row {
    flex-direction: column;
  }
  .firstDay__item {
    margin-bottom: 30px;
  }
}

@media (max-width:480px) {
  .accordion__container {
      margin-bottom: 50px;
    }
  .firstDay {
      margin-bottom: 12%;
    }
  .firstDay__container {
      grid-template-columns: 1fr;
      grid-gap: 30px;
    }
  .list__title {
    font-size: 18px;
    margin-bottom: 35px;
  }

  .list__title span {
    font-size: 24px;
    margin-right: 10px;
  }

  .list__title::after {
    bottom: -15px;
    width: 80px;
  }

  .list__text {
    font-size: 14px;
    line-height: 140%;
  }
}

@media (max-width:380px) {
    .list__title {
        font-size: 16px;
      }
}

*/
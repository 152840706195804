.main {
  background:  rgba(255, 255, 255, 0.5)  ;
  min-height: 836px;
  background-size: cover;
  background-position: center;
  transition: all 1sec;

}


.main_poster {
  position: absolute;
  z-index: -10;
  top:0;
  left: 0;
  width: 100%;
  height:95%;
  background: url('../../assets/img/mainimg.jpg');
  background-size: cover;
  background-position: center;
} 

.video {
  position: relative;
  z-index: -3;
  width: 100vw;
  overflow: hidden;
}

.main__container {
  max-height: 836px;
  position: absolute;
  top: 83px;
  left: 0;
  z-index: -3;
  object-fit: cover;
  width: 100%;
  overflow: hidden;
}


@media (max-width:1440px) {

}

@media (max-width:1080px) {
  .main_poster {
  
  }
  .main {
    min-height: 650px;
  }
}

@media (max-width:780px) {
  .main {
    min-height: 550px;
  }

  video {
    height: 500px;
  }
}
@media (max-width:670px) {
  .video {
    width: 150vw;
  }
}
@media (max-width:430px) {
  .video {
    width: 230vw;
  }
}



.main__column {
  position: relative;
  padding-top: 148px;
  }
  @media (max-width:1080px) {
    .main__column {
      padding-top: 100px;
    }
  }
  @media (max-width:720px) {
    .main__column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

.main__title {
  color: var(--grey-text);
  font-family: Inter;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
  max-width: 70%;
}
@media (max-width:1080px) {
  .main__title {
    font-size: 48px;
  }
}
@media (max-width:720px) {
  .main__title {
    max-width: 80%;
    font-size: 36px;
  }
}
@media (max-width:560px) {
  .main__title {
    max-width: 80%;
    font-size: 28px;
  }
}
@media (max-width:450px) {
  .main__title {
    font-size: 24px;
  }
}
@media (max-width:350px) {
  .main__title {
    max-width: 100%;

  }
}

.main__subtitle {
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 50px;
}
@media (max-width:1080px) {
  .main__subtitle {
    font-size: 20px;
    margin-bottom: 20px;
  }
}
@media (max-width:720px) {
  .main__subtitle {
    font-size: 18px;
  }
}
@media (max-width:560px) {
  .main__subtitle {
    font-size: 16px;
  }
}
@media (max-width:450px) {
  .main__subtitle {
    font-size: 14px;
  }
}

.main__subtitle span {
  color:var(--blue);
  font-weight: 700;
}

.main__column {
  max-width: 730px;
}

.main__link {
  display: flex;
  align-items: center;
  justify-content: left;
  background: var(--second-blue);
  max-width: 282px;
}
.main__link:hover span {
  color:#D5DFE6;
}


.main__link span {
  margin: 0px 26px;
  display: inline-block;
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}

@media (max-width:560px) {
  .main__link span {
    font-size: 16px;
  }
}
@media (max-width:350px) {
  .main__link span {
    font-size: 14px;
    margin: 0px 20px;
  }
}
@media (max-width:280px) {
  .main__link span {
    font-size: 12px;
    margin: 0px 14px;
  }
}

.main__link img {
  padding: 15px 20px;
  display: inline-block;
  background-color: var(--second-blue);
}
@media (max-width:350px) {
  .main__link img {
    padding: 10px 15px;
  }
}

.main__link:hover {
  background-color: var(--second-blue);
}
.main__link:active span {
  color:var(--grey);
}

.main__arrow {
  position: relative;
  width: 100%;
}
.arrow {
  position: fixed;
  z-index: 2;;
  cursor: pointer;
  bottom: 20px;
  right: calc(100vw/2 - 48rem);
}

@media (max-width:1650px) {
  .arrow {
      right: 1rem;
    }
}
@media (max-width:740px) {
  .arrow {
      display: none;
    }
}

.footer__links_img:hover * {
  fill: var(--main-blue);
}

.nextSmall:hover * {
  fill: var(--main-blue);
}



/* "homepage": "https://EkaterynaSholomickaya.github.io/UTS",*/
/*             https: //EkaterynaSholomickaya.github.io/gk_uts */

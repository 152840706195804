.offers {
  position: relative;
}

.offers__el {
  position: absolute;
  top: -83px;
  width: 1px;
}

.offers__text {
  display: block;
  max-width: 1200px;
  margin-bottom: 60px;
  text-align: center;
  color: var(--dark-grey, #2F3338);
  font-size: 24px;
  margin-left: 0;
}

.offers__text span {
  font-weight: 700;
}

.offers__text_container {
  margin-bottom: 50px;
}

.offers__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.offers__title  {
  text-align: center;
  margin-bottom: 80px;

}
.offers__title span {
  color: var(--main-blue);
}


.offers__table {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 60px;
}

.offer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 70px 50px;
  margin: 0px 10px 20px;
  width: 680px;
  /* min-height: 255px; */
  border: 2px solid var(--dark-grey);
}

.offer__img {
  text-align: center;
  align-self: center;
  justify-self: center;
  flex:0 0 20%;
}

.offer__text { 
  flex: 0 0 67%;
  color: var(--dark-grey, #2F3338);
  font-size: 22px;
  line-height: 140%;
}

@media (max-width: 1480px) {
.offers__table {
    margin-bottom: 8%;
  }
.offer {
  width: auto;
  min-width: 480px;
  padding: 40px 30px;
}
.offers__text {
  margin-bottom: 70px;
}
.offer__text,
.offers__text {
    font-size: 20px;
  }
}
.offer__img {
  width: 80px;
}

@media (max-width: 1080px) {

.offer {
  padding: 30px;
  flex-direction: column;
  min-width: 360px;
}
.offer__text, .offers__text {
  text-align: center;
    font-size: 18px;
}

.offer__img {
  height:  80px;
  width: auto;
  max-width: 100px;
  margin-bottom: 20px;
}
}


@media (max-width: 870px) {
  .offer__text, .offers__text {
    font-size: 18px;
  }
  .offers__table {

  grid-template-columns: repeat(1, 1fr);

}
}

@media (max-width: 540px) {
  .offer__text, .offers__text {
    font-size: 16px;
  }
}

@media (max-width: 430px) {
.offer {
    min-width: 200px;
  }

}
.offer__text span {
  font-weight: 700;
}
.value {
  display: flex;
  align-items: start;
  justify-content: jeft;
  column-gap: 40px;
  margin-bottom: 40px;

}

.value__img {   
  width: 134px;
  height: 134px;

  flex-shrink: 0;
  border-radius: 50%;
  background-color: var(--main-blue);
  display: flex;
  align-items: center;
  justify-content: center;

}

.value__title {
  margin-bottom: 16px;
  color: var(--dark-grey, #2F3338);
  font-size: 24px;
  font-weight: 700;
}

.value__text {
    font-size: 24px;
    font-weight: 400;
}

@media (max-width:1240px) {
.value__text {
    font-size: 20px;
  }
.value__title {
  font-size: 20px;
  margin-bottom: 6px;
}
.value__img {
  width: 90px;
  height: 90px;
}
.value__img img {
  width: 50px;
}
}
@media (max-width:870px) {
.value__text {
    font-size: 16px;
  }

.value__title {
    font-size: 16px;
    margin-bottom: 0px;
  }

.value__img {
    width: 90px;
    height: 90px;
  }
.value__img img {
    width: 50px;
  }
}

@media (max-width:870px) {
.value {
  margin: 0 auto;
  width: 80%;
  flex-direction: column;
  align-items: center;
  justify-content: jeft;
  column-gap: 20px;
  margin-bottom: 40px;
}
.value__img {
margin-bottom: 20px;
}
.value__title {
text-align: center;
}
.value__text {
  text-align: center;
}
}
.data {
  position: relative;
  margin-bottom: 120px;
}
.data__el {
  position: absolute;
  top: -83px;
  width: 1px;
}

.data__container {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.title__container {
  margin-bottom: 40px;
}

.cards:last-child {
  font-weight: 700;
  font-size: 28px;
}

.data__text {
  position: relative;
  max-width: 65%;
  color: var(--dark-grey, #2F3338);
}
.data__text p {
  margin-bottom: 14px;
}
.data__text ol {
  list-style-type:disc;
  margin-left: 34px;
}



@media (max-width: 1400px) {
.data {
    margin-bottom: 10%;
  }
}
@media (max-width: 1080px) {
  .data__text {
    font-size: 20px;
  }
}

@media (max-width: 870px) {
  .data__text {
    font-size: 18px;
  }
}

@media (max-width: 540px) {
  .data__text {
    font-size: 16px;
  }
}

.data__text span {
  font-weight: 700;
}
@media (max-width:1280px) {
  .data__container {
    
      flex-direction: column;
      align-items: stretch;
  }
    .data__text {
      max-width: 100%;
    }
}

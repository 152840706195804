.header {
  position: sticky;
  z-index: 22;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px 0;
}

.header__row {
  position: relative;
  z-index: 21;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);

}

.logo {
  height: 100%;
  width: auto;
}
.header__link {
  color: var(--dark-grey);
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
}
@media (max-width: 1080px) {
  .header__link {
    font-size: 16px;
  }
}


.header__link_btn {
  padding: 8px 40px;
  color: var(--white);
  background: var(--second-blue);
  margin-left: 75px;
}
@media (max-width: 1480px) {
  .header__link_btn {
    margin-left: 10px;
  }
}
@media (max-width: 1280px) {
  .header__link_btn {
    margin-left: 0px;
  }
}

.header__nav {
  position: relative;
}


@media (max-width: 770px) {
  .header__nav {
    position: absolute;
    z-index: 1;
    top: -430px;
    right: 0px;
  }
  .header__bg {
    opacity: 0;
    display: block; 
    top:71px;    
    bottom: 100%;
    left: 0;
    right: 0;
    position: fixed;
    z-index: -10;    
    background-color: rgba(0, 0, 0,0.2); 
    transition: opacity .5s;
  }
  .header__bg.activeBg {
    bottom: 0%;
    opacity: 1;
  }
  .header__nav.active {
    right:0px;
    top:-100px;
  }

}


.header__nav ul {
  display: flex;  
  column-gap: 60px;
}

@media (max-width:770px) {
  .header__nav ul {
    min-width: 200px;
    min-height: 180px;
    margin-top: 30px;
    padding-top: 20px;
    background-color: var(--white);
    z-index: 3;
    position:absolute;
    top:110px;
    right:0px;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s linear;
  }
}

@media (max-width:770px) {
  .header__nav>ul>li {
    margin-bottom: 40px;
  }
}

@media (max-width:1440px) {
  .header__link_btn {
    column-gap: 40px;
  }
}
@media (max-width:770px) {
  .header__link {
    margin-bottom: 30px;
  }
}

.header__link::after {
    display: block;
    position: absolute;
    left: 0;
    width: 0;
    height: 3px;
    background-color: var(--dark-grey);
    content: "";
    transition: width 0.3s ease-out;

}

.header__link:hover:after,
.header__link:focus:after {
  width: 100%;
}




.header__link:active:after{
  background-color: var(--main-blue);
}
.header__link:active {
  color:var(--main-blue);
}


.header__link_btn:hover {
  background-color: var(--blue);
}
.header__link_btn:active {
  color: var(--grey);
}

.header__link_btn:hover {
color:var(--grey);

}
.header__link_btn:hover:after,
.header__link_btn:focus:after {
  width: 0%;
}


.header__logo img {
  
  max-height: 50px;
}
@media (max-width:1440px) {
  .header__logo img {
     
  }
}


  /* .header__menu_button {
    display: none;
  } */

.header__menu_button {
  display: none;
  position: relative;
  z-index: 20;
  cursor: pointer;
}


@media (max-width:770px) {
  .header__menu_button {
    display: block;
    margin-left: auto;
    margin-right: 10px;
    padding: 0;
    border: 0;
    width: 25px;
    height: 10px;
    background-color: transparent;
  }
}

.icon__top, .icon__bottom {
  width: 26px;
  height: 3px;
  margin-bottom: 10px;
  background-color: var(--dark-grey);
}

.icon__bottom.active {
  transform: rotate(-45deg)   translateY(-9px);

}
.icon__top.active {
  transform: rotate(45deg) translateY(9px);
}

.icon__top {

  height: 3px;

}
.accordion {
  width: 100%;
}

.accordin__item {
}

.accordion__header {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  background: var(--white-blue);
  border: 2px solid var(--grey);
  color: var(--dark-grey, #2F3338);
  font-size: 28px;
  font-weight: 700;
  line-height: 148.023%;
}
.bigF .accordion__header {
    background: var(--white);
    border: none;
}

.accordion__header.blue *, .accordion__header.blue {
  background: var(--blue);
  color: var(--white, #ffffff);
}

.accordion__header.blue {

  border: 2px solid var(--main-blue);
}

.accordion__header.blue * {
  margin-bottom: 0;
}

.accordion__header:hover {
  cursor: pointer;
}

.accordion__header.blue .according__arrow * {
  fill: var(--grey);
}


.accordion__body {
  padding-top: 0;
  color: var(--grey-text, #242424);
  font-size: 24px;
  font-weight: 400;
  line-height: 150%;
}
.accordion__body::first-line {
  padding: 0;
  margin: 0;
}

.accordin__collapse {
  height: 0;
  overflow: hidden;
  transition: height 0.3s;
}

.accordin__collapse.open {
  height: auto;
}

.according__arrow {
  position: absolute;
  top: 50%;
  right: 20px;
  display: block;
  width: 30px;
  height: 17px;
  transform: translateY(-50%);
  transition: all 0.3s;
}

.according__arrow * {
  fill: var(--dark-grey);
}

.according__arrow.active {
  transform: translateY(-50%) rotate(180deg);
}


li {
  font-style: 24px;
  font-weight: 300;
}

span {
  font-weight: 700;
}

@media (max-width:1480px) {
.accordion__header {
  font-size: 24px;
}
.accordion__body {
  font-size: 20px;
  padding: 0 25px 25px;
}
.accordion__body * {
  font-size: 20px;
}
}


@media (max-width:1280px) {
.accordion__header {
    padding: 10px 40px 10px 30px;
    font-size: 20px;
  }
  .accordion__body {
    font-size: 16px;
    padding: 0 25px 25px;
  }
  .accordion__body * {
    font-size: 16px;
  }
  .according__arrow {
    width: 15px;
  }
}

@media (max-width:1080px) {
.accordion__header {
    padding-left: 20px ;
  }
}

@media (max-width:870px) {}

@media (max-width:480px) {
.accordion__header {
    padding: 5px 35px 5px 10px;
    font-size: 16px;
  }

  .accordion__body {
    font-size: 14px;
    padding: 0 25px 25px;
  }

  .accordion__body * {
    font-size: 14px;
  }

  .according__arrow {
    width: 15px;
  }
}


.bigF{
  width: 100%;
  margin-bottom: 0;
}


.bigF>.accordin__collapse>.accordion__body {
  padding: 0px;
}

.darckblue {
  background: var(--second-blue);
}


.darckblue>.according__arrow * {
  fill:var(--white);
}

.white>.according__arrow * {
  fill:var(--second-blue);
}
/* .white ~ .accordin__collapse {
  padding:  60px 0 60px;;
} */


.bigF>.accordion__header>.according__arrow {
  right: 20px;
}
.bigF>.accordion__header>.according__arrow *{
  fill: #2F3338;
}

@media (max-width:1480px) {
  .bigF>.accordion__header>.according__arrow {
      right: 25px;
    }
}

.bigF>.accordion__header:hover {
  background-color: var(--white-blue);
}
.bigF>.accordion__header {
  padding: 15px 10px 15px 10px;
  margin-bottom: 0;
  border: none;
}
.bigBody {
  padding: 100px;
}
.accordion__body {
  padding: 10px 0 50px;
}
.accordion__body.bigBody{
  padding: 310px;
}
.firstMonth__list {
  list-style: inside;
}
.firstMonth__list li {
  margin-bottom: 10px;
}
.firstMonth__secondlist_caption{
    padding-left: 2%;
    margin-top: 15px;
    margin-bottom: 25px;

}
.firstMonth__secondlist {
  margin-top: 15px;
  padding-left: 2%;
  list-style: decimal;
}

.firstMonth__questions {
  width: 100%;
  background-color: var(--white-blue);
  font-weight: 800;
  margin-bottom: 15px;
  padding: 10px;
}
.firstMonth__questions_text {
  color: var(--dark-grey);

}
.firstMonth__p {
  margin-bottom: 15px;}


.firstMonth {
  padding-bottom: 10px;
  padding-top: 50px;
}
.firstMonth__link {
  color: var(--dark-grey);
  font-weight: 700;
  text-decoration: underline;
}

/* .firstMonth__questions_text {
margin-bottom: 510px;
} */

/* .firstMonth {
  
  padding: 3% 0;
}

.title__container {
  max-width: 800px;
  text-align: center;
  margin: 0 auto; 
  margin-bottom: 70px;
}

.firstMonth__text {
  padding: 22px 60px;
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 700;
  line-height: 148.023%;
  border:3px solid var(--grey);
}

.firstMonth__text span {
  display: block;
  font-size: 20px;
  font-weight: 300;
  margin-left: 50px;
  line-height: 130%;
}


@media (max-width:1480px) {

.title__container {

    margin-bottom: 10%;
  }
.firstMonth__text {
    padding: 22px 40px;
    margin-bottom: 25px;
    font-size: 24px;
  }
.firstMonth__text span {
  font-size: 18px;
  margin-left: 25px;
}
}


@media (max-width:1280px) {
.firstMonth__text {
    padding: 22px 30px;
    margin-bottom: 20px;
    font-size: 20px;
  }
.firstMonth__text span {
  font-size: 16px;
  margin-left: 20px;
}
}

@media (max-width:1080px) {
.firstMonth__text {
    padding: 16px 20px;
    margin-bottom: 15px;
    font-size: 16px;
  }
.firstMonth__text span {
  font-size: 14px;
  margin-left: 10px;
  margin-top: 10px;
}
}

@media (max-width:870px) {

}

@media (max-width:480px) {

} */
.title {
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px; 

}

.title *{
  display: inline-block;
}

.title span {
  color:var(--main-blue) ;
}

.center {
  text-align: center;
  align-items: center;
}

.white {
  color:var(--white);
}

.line {
  width: 82px;
  height: 3px;
  margin-top: 15px;
  background-color: var(--main-blue);
}

.yellow {
  background-color: var(--yellow);
}





